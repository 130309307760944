img{width:100%;}
.no-line-height{line-height:0;}
ul{margin:0; padding:0; list-style:none;}
ul li{  padding:0 0 0 25px; position:relative;}
ul li input{position: absolute; top:5px; left:0; opacity:0.5;}

.bg-blue-tile{background: #007FFF url(../assets/tile.png); position:relative;}
@media all and (min-width:40em){
.bg-blue-tile:after{position:absolute; top:0; left:0; right:0; bottom:0; content:" "; box-shadow:inset 0 0 100px 20px #007FFF; z-index:1;}
}

.max-width-4{max-width:64em}
.max-width-3{max-width:52em}
.max-width-2{max-width:40em}

.search input{border-radius:5px; border:2px solid #eee; box-shadow:0 0 14px rgba(#007FFF,0.1); width:100%; padding:1.5rem}
.search input:focus{outline:none; border:2px solid #007FFF;}
.search{transition:.3s ease height; height:90vh; display:flex; flex-direction: column; justify-content:center;}

.hero{height:150px;}
.post h1{margin-top:6rem}
.post ol, .post ul{}
.post li{margin-bottom:1rem; padding:0 0 0 1rem;}
.post li:before{content:"- "; position:absolute; left:0; color:#007FFF}

a .image{transition:.2s ease all; opacity:1;}
a:hover .image{opacity:0.2;}
.ingredients p{margin-bottom:0.5rem;}

.sell .sm-col a{text-decoration:none; color:#fff; border-bottom:2px solid rgba(255,255,255,0.5);}

.ratio{padding-top:50%; background-position: center center; background-repeat:no-repeat;}

@media all and (max-width:40em){
.left-align{text-align:center;}
.right-align{text-align:center;}
}

@media all and (max-width: 300px){
.title{font-size:1.3rem; text-align:center; box-sizing:border-box; width:100%;}
.xs-center{text-align: center}
.site-title{bottom:-28px; top:auto; left:50%; margin-left:-25px; border-radius:200px; transition:.2s ease bottom}
.site-title:hover{bottom:-14px;}
.recipes .sm-col{border-bottom:3px solid white}
}